import { grid, hyphenate, layoutWrapper, linkHover } from '@mixins';
import React from 'react';
import styled from 'styled-components';

import LogoLinesSvg from '@vectors/logo-lines.svg';
import Link from '@core/Link';
import mq, { mqdown } from '@mq';
import isBrowser from '@utils/isBrowser';

const Footer = ({ missionStatement, siteInfo, credits, homepageUrl }) => (
  <Container>
    <Logo to={homepageUrl}>
      <LogoLinesSvg />
    </Logo>

    <MissionStatement>{missionStatement}</MissionStatement>
    <SiteInfo dangerouslySetInnerHTML={{ __html: siteInfo }} />
    <Credits>
      <div dangerouslySetInnerHTML={{ __html: credits }} />
      <CookieRenewButton
        onClick={() => {
          if (isBrowser) {
            window.Cookiebot.renew();
          }
        }}
      >
        Cookies
      </CookieRenewButton>
    </Credits>
  </Container>
);

const Container = styled.footer`
  ${layoutWrapper}
  ${grid};

  position: relative;

  &::before {
    margin-left: var(--grid-margin);
    margin-right: var(--grid-margin);
    content: '';
    display: block;
    position: absolute;
    top: -1.5rem;
    right: 0;
    left: 0;
    border-top: 0.1875rem solid var(--color-text);
  }

  padding-bottom: 3rem;
  margin-top: 10rem;

  ${mq.small} {
    margin-top: 20rem;
  }
`;

const Logo = styled(Link)`
  grid-column: 1 / -3;
  width: 3.125rem;

  ${mqdown.medium} {
    margin-bottom: 1.5rem;
  }

  ${mq.medium} {
    grid-column: 1;
    position: relative;
    top: 0.2rem;
  }
`;

const MissionStatement = styled.div`
  grid-column: 1 / -3;
  font-weight: bold;

  ${mqdown.medium} {
    margin-bottom: 1.5rem;
  }

  ${mq.medium} {
    grid-column: 2 / 5;
    padding-right: 2.25rem;
  }
`;

const SiteInfo = styled.div`
  grid-column: 1 / -3;
  display: grid;
  row-gap: 1em;
  align-content: space-between;

  ${mqdown.medium} {
    margin-bottom: 1em;
  }

  ${mq.medium} {
    grid-column: 5 / 7;
  }

  a {
    ${linkHover};
    text-decoration: underline;
  }
`;

const Credits = styled.div`
  grid-column: 1 / -3;
  display: grid;
  row-gap: 1em;
  align-content: space-between;

  > div {
    display: grid;
    row-gap: 1em;
    align-content: space-between;
  }

  ${mq.medium} {
    grid-column: 7 / 9;
  }

  a {
    ${linkHover};
    text-decoration: underline;
  }
`;

const CookieRenewButton = styled.div`
  ${linkHover};
  text-decoration: underline;
`;

export default Footer;

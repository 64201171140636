import React, { useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import GlobalStyles from '@styles/GlobalStyles';
import Footer from '@core/Footer';
import isBrowser from '@utils/isBrowser';
import Seo from '@core/Seo';

const Layout = ({
  children,
  data: {
    prismicFooter: {
      data: {
        mission_statement: { text: missionStatement },
        site_info: { html: siteInfo },
        credits: { html: credits },
      },
    },
    prismicHomepage: { url: homepageUrl },
    prismicGeneral: {
      data: { page_description: pageDescription, page_title: pageTitle },
    },
  },
}) => {
  const handlePageTransitionEnd = useCallback(() => {
    if (isBrowser) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <GlobalStyles />
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <AnimatePresence mode="wait" onExitComplete={handlePageTransitionEnd}>
        {React.Children.map(children, (childElement, index) => (
          <PageTransition key={index}>
            {React.cloneElement(childElement, {})}
            <Footer
              missionStatement={missionStatement}
              siteInfo={siteInfo}
              credits={credits}
              homepageUrl={homepageUrl}
            />
          </PageTransition>
        ))}
      </AnimatePresence>
    </>
  );
};

const PageTransition = ({ children }) => (
  <motion.div
    initial={{
      opacity: 0,
    }}
    animate={{
      opacity: 1,
    }}
    exit={{
      opacity: 0,
    }}
    transition={{
      duration: 0.65,
    }}
  >
    {children}
  </motion.div>
);

export default Layout;

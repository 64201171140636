import mq, { hover, mqdown } from '@mq';
import { createGlobalStyle } from 'styled-components';

const Base = createGlobalStyle`
  html,
  body {
    font-size: 16px;
    line-height: 1.2;
    background: var(--color-background);
    color: var(--color-text);
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;

  }

  main,
  nav,
  footer {
    max-width: 1360px;
    margin: 0 auto;
  }

  main {
    font: var(--font-body);
  }

  nav, footer {
    font: var(--font-small);
  }

  h1 {
    font: var(--font-h1);
  }

  h2 {
    font: var(--font-h2);
  }

  h3 {
    font: var(--font-h3);
  }

  .headroom {
    z-index: 4 !important;
  }

  ::selection {
    color: var(--color-text);
    background-color: var(--color-pink);
  }
`;

export default Base;

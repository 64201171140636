import { hover } from '@mq';
import { css, keyframes } from 'styled-components';

const layoutWrapper = css`
  padding-left: var(--grid-margin);
  padding-right: var(--grid-margin);
`;

const grid = css`
  display: grid;
  column-gap: var(--grid-gutter);
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
`;

const hyphenate = css`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`;

const truncate = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

const richText = css`
  p {
    /* white-space: pre-line; */
    /* z-index: 1; */
    /* position: relative; */
  }

  p + p {
    margin-top: 1em;
  }

  //first paragraph after an h2 or h3
  h2 + p,
  h3 + p,
  ul + p {
    margin-top: 1em;
  }

  h2 {
    margin-top: 6rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 {
    margin-top: 3.75rem;
    margin-bottom: 1.875rem;

    &:first-child {
      margin-top: 0;
    }
  }

  h3 + h3 {
    margin-top: 0;
  }

  h2 + h3 {
    margin-top: 1.5rem;
  }

  ul {
    /* margin-top: 0.75rem; */

    li {
      display: flex;

      &:before {
        content: '•';
        text-transform: uppercase;
        display: block;
        margin: 0 0.5rem;
        height: 100%;
        font-weight: bold;
        font-size: 1.8em;
        position: relative;
        bottom: 0.33em;
      }
    }
  }

  a {
    text-decoration: underline;
  }
`;

const appearKeyframes = keyframes`
  from {
    opacity:  0;
  }

  to {
    opacity:  1;
  }
`;

const appear = css`
  animation: ${appearKeyframes} 1s var(--motion-delay, 0s) backwards;
`;

const transition = {
  transform: css`
    transform: translate3d(0, 0, 0);
    transition: transform var(--motion) ease-out;
  `,
};

const unflicker = css`
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
`;

const linkHover = css`
  transition: color var(--motion-double);
  cursor: pointer;

  ${hover} {
    &:hover {
      color: var(--color-pink);
    }
  }
`;

export {
  richText,
  grid,
  truncate,
  layoutWrapper,
  center,
  hyphenate,
  cover,
  appear,
  transition,
  unflicker,
  linkHover,
};

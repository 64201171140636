import { createGlobalStyle } from 'styled-components';
import mq from '@mq';

const Vars = createGlobalStyle`
  :root {
    --color-black: #101010;
    --color-white: #fff;
    
    --color-text: var(--color-black);
    --color-background: var(--color-white);

    --color-blue: #0050FF;
    --color-red: #EB5D45;
    --color-pink: #EF93B8;
    --color-yellow: #FFD74B;
    --color-orange: #FF7B3C;
    --color-turquoise: #5FD7C0;


    --grid-margin: 2.25rem; //36
    --grid-gutter: 0.625rem; //10
    --grid-column-count: 8;
    
    ${mq.tiny} {
      --grid-margin: 2.5rem; //40
    }

    ${mq.small} {
      --grid-margin: 5.625rem; //90
      --grid-gutter: 1.25rem; //20
    }
    
    ${mq.medium} {
      --grid-margin: 12.5rem; //200
    }

    --navigation-height: 1.5rem; //24
    
    ${mq.small} {
      --navigation-height: 2.5rem; //40
    }

    --font-family: 'Riforma LL', sans-serif;

    --font-small:0.75rem / 1.0125 var(--font-family); //12
    --font-body: 1rem / 1.1875 var(--font-family); //16
    --font-h3: 700 1rem / 1.1875 var(--font-family); //16
    --font-h2: 700 1.25rem / 1.3 var(--font-family); //20
    --font-h1: var(--font-h2);

    --font-objective-index: 700 2rem / 1 var(--font-family); //32
    --font-xl: 700 2.5rem / 1.1 var(--font-family); //40
    
    ${mq.small} {
      --font-small: 1rem / 1.1875 var(--font-family); //16
      --font-body: 1.75rem / 1.14285714 var(--font-family); //28
      --font-h3: 700  1.75rem / 1.14285714 var(--font-family); //28
      --font-h2: 700 2.5rem / 1.1 var(--font-family); //40
      
      --font-objective-index: 700 2.5rem / 1 var(--font-family); //32
      --font-xl: 700 3.75rem / 1.16666667 var(--font-family); //60
    }
    
    --motion-half: 50ms;
    --motion: 100ms;
    --motion-double: 200ms;
    --motion-triple: 300ms;
    --motion-quadruple: 400ms;
    --motion-quintuple: 500ms;

    //scroll track length in viewports (x * viewport height)
    --scroll-track-length: 2;
    --scroll-illustration-height: 70vh;
    --objectives-stack-speed: 0.75s;
  }
`;

export default Vars;

const defaultLanguage = 'it-it';

function linkResolver(doc) {
  switch (doc.type) {
    case 'homepage':
      return doc.lang === defaultLanguage ? '/' : `/${langMapper(doc.lang)}`;

    case 'about_page':
    case 'colophon':
    case 'privacy_policy':
      return doc.lang === defaultLanguage ? `/${doc.uid}` : `/${langMapper(doc.lang)}/${doc.uid}`;

    default:
      return '/';
  }
}

function langMapper({ lang }) {
  if (lang === 'en-gb') {
    return 'en';
  }

  return 'en';
}

module.exports = linkResolver;
